import * as React from 'react';
import { useContext } from 'react';
import {
  Alert,
  PageSection,
  TextInput,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Bullseye,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  FormAlert,
  FormSelect,
  FormSelectOption,
  Button,
  Title,
  Grid,
  GridItem,
  HelperText,
  HelperTextItem,
} from '@patternfly/react-core';
import {
  GetProjectResponse,
  member_StateToJSON,
  member_RoleToJSON,
  accessModeToJSON,
  Project as PortalProj,
} from '@mergetb/api/portal/v1/workspace_types';
import { useParams, Link } from 'react-router-dom';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { useFetch } from 'use-http';
import { FetchSelect } from '@app/lib/FetchSelect';
import { sortable, headerCol } from '@patternfly/react-table';
import { ActionList } from '@app/lib/ActionList';
import { DetailEntry, DetailsTable } from '@app/lib/DetailsTable';
import { TaskStatusTable } from '@app/lib/TaskStatusTable';
import { GRPCError } from '@app/lib/error';

const Project: React.FunctionComponent = () => {
  const { pid } = useParams();
  const conf = useContext(GeneralSettingsContext);
  const [reload, setReload] = React.useState(0);

  const options = { credentials: 'include', cachePolicy: 'no-cache' };
  const url = conf.api + '/project/' + pid;

  const st_url = url + '?statusMS=-1';
  const st_getter = (data) => {
    return data.status;
  };

  const [proj, setProj] = React.useState<PortalProj>();
  const [loadError, setLoadError] = React.useState<GRPCError>();
  const { loading, error, get, response } = useFetch(url, options, []);

  const load = React.useCallback(async () => {
    const resp = await get();
    if (response.ok) {
      setProj(GetProjectResponse.fromJSON(resp).project);
    } else {
      setLoadError(resp);
    }
  }, [get, response]);

  React.useEffect(() => {
    load();
  }, [reload]);

  const crumbs = (
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="../project">Projects</BreadcrumbItem>
        <BreadcrumbItem>{pid}</BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
  );

  const details: Array<DetailEntry> = proj
    ? [
        { label: 'Name', value: proj.name },
        {
          label: 'Organization',
          value: proj.organization ? (
            <>
              <Link to={'/organization/' + proj.organization}>{proj.organization}</Link> /{' '}
              {proj.orgMembership && member_StateToJSON(proj.orgMembership.state)}
            </>
          ) : (
            ''
          ),
        },
        {
          label: 'Experiments',
          value: (
            <>
              {proj.experiments.sort().map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <Link to={'/project/' + pid + '/experiment/' + e}>{e}</Link>
                    <br />
                  </React.Fragment>
                );
              })}
            </>
          ),
        },
        { label: 'Category', value: proj.category },
        { label: 'Subcategory', value: proj.subcategory },
        { label: 'Access Mode', value: accessModeToJSON(proj.accessMode) },
      ]
    : [];

  return (
    <React.Fragment>
      {crumbs}
      <PageSection>
        <Title headingLevel="h1" size="3xl">
          Project: {pid}
        </Title>
      </PageSection>
      <PageSection>
        <Grid hasGutter>
          <GridItem>
            <Card id={pid + '-card-id'}>
              <CardHeader id={pid + 'card-header-id'}>
                <CardTitle>Details</CardTitle>
              </CardHeader>
              <CardBody>
                {error && !loadError && (
                  <Alert variant="danger" title="Error">
                    Error loading
                  </Alert>
                )}
                {error && loadError && (
                  <Alert variant="danger" title="Response Error">
                    {loadError.message}
                  </Alert>
                )}
                {loading && (
                  <Bullseye>
                    <Spinner size="sm" />
                  </Bullseye>
                )}
                {proj && <DetailsTable label="projectdetails" entries={details} />}
              </CardBody>
            </Card>
          </GridItem>
          {proj && (
            <GridItem>
              <Members
                project={proj.name}
                members={proj.members}
                reload={ () => { setReload(reload + 1); } }
              />
            </GridItem>
          )}
          <GridItem>
            <Card id="statusCard">
              <CardHeader>
                <CardTitle id="statusCardTitle">Status</CardTitle>
              </CardHeader>
              <CardBody>
                <TaskStatusTable
                  kind={pid + '-tst'}
                  url={st_url}
                  getter={st_getter}
                  ongoingfrequency={2000}
                  completedfrequency={600000}
                  scalingfrequency={1.0 / 10.0}
                  reload={reload}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  );
};

interface NewMemberProps {
  project: string;
  members: Map<string, Member>;
  isOpen: any;
  onClose: any;
}

const NewMember: React.FunctionComponent<NewMemberProps> = (props) => {
  const conf = useContext(GeneralSettingsContext);
  const [member, setMember] = React.useState({ value: '', valid: 'error' });
  const [memState, setMemState] = React.useState('Active');
  const [role, setRole] = React.useState('Member');

  const submitForm = () => {
    fetch(conf.api + '/project/' + props.project + '/member/' + member.value, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        member: {
          role: role,
          state: memState,
        },
      }),
    })
      .then((response) => {
        props.onClose();
        setMember({ value: '', valid: 'error' });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onMemberSelect = (val) => {
    setMember({ value: val, valid: val === '' ? 'error' : 'success' });
  };

  function mapUsers(json: Record<string, unknown>): Array<string> {
    // filter out existing users from current members.
    const notExistingMember = (element) => {
      let exists = false;
      Object.keys(props.members).forEach((e) => {
        if (e === element.username) {
          exists = true;
        }
      });
      return !exists;
    };

    const notMembers = json.users.filter(notExistingMember);
    return notMembers.map((u) => u.username);
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} variant={ModalVariant.medium} aria-label="Add Project Member">
      <Card>
        <CardHeader>Add Project Member</CardHeader>
        <CardBody>
          <Form isHorizontal>
            {(member.valid !== 'success' || member.valid !== 'success') && (
              <FormAlert>
                <Alert variant="danger" title="All required fields must be filled" aria-live="polite" isInline />
              </FormAlert>
            )}
            <FormGroup fieldId="project" label="Project">
              <TextInput value={props.project.name} aria-label="Project" isDisabled />
            </FormGroup>
            <FormGroup fieldId="member" label="Member" isRequired={true}>
              <FetchSelect
                placeholderText="Choose a new Member"
                label="Member"
                url={conf.api + '/users'}
                onSelect={onMemberSelect}
                mapItems={mapUsers}
              />
              {member.valid === 'error' && (
                <HelperText>
                  <HelperTextItem variant="error">Please select a member</HelperTextItem>
                </HelperText>
              )}
            </FormGroup>
            <FormGroup fieldId="role" label="Role">
              <FormSelect value={role} aria-label="Role" onChange={(_event, v) => setRole(v)}>
                <FormSelectOption key={0} value="Member" label="Member" />
                <FormSelectOption key={1} value="Maintainer" label="Maintainer" />
                <FormSelectOption key={2} value="Creator" label="Creator" />
              </FormSelect>
            </FormGroup>
            <FormGroup fieldId="state" label="State">
              <FormSelect value={memState} aria-label="State" onChange={(_event, v) => setMemState(v)}>
                <FormSelectOption key={0} value="Active" label="Active" />
                <FormSelectOption key={1} value="Pending" label="Pending" />
              </FormSelect>
            </FormGroup>
            <FormGroup fieldId="submit">
              <Button
                variant="primary"
                onClick={submitForm}
                isActive={member.valid !== 'error' && member.valid !== 'error'}
                isAriaDisabled={member.valid === 'error' || member.valid === 'error'}
              >
                Submit
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

interface Member {
  username: string;
  role: string;
  state: string;
}

interface MembersProps {
  project: string;
  members: Map<string, Member>;
  reload: any;
}

const Members: React.FunctionComponent<MembersProps> = ({ project, members, reload }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const conf = useContext(GeneralSettingsContext);

  const cols = [
    { title: 'Username', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'Role', cellTransforms: [headerCol()], transforms: [sortable] },
    { title: 'State', cellTransforms: [headerCol()], transforms: [sortable] },
  ];
  const rows = Object.entries(members).map(([username, member]) => ({
    username: {
      title: <Link to={'/user/' + username}>{username}</Link>,
      props: { text: username },
    },
    role: member_RoleToJSON(member.role),
    state: member_StateToJSON(member.state),
  }));

  const updateMember = (member, role, state) => {
    fetch(conf.api + '/project/' + project + '/member/' + member, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        member: {
          role: role,
          state: state,
        },
      }),
    })
      .then((response) => {
        reload();
      })
      .catch((error) => console.log(error));
  };

  const actions = [
    {
      title: 'Set as maintainer',
      onClick: (event, rowId, rowData) => {
        const member = rowData.username.props.text;
        const state = rowData.state;
        const role = 'Maintainer';
        updateMember(member, role, state);
      },
    },
    {
      title: 'Set as member',
      onClick: (event, rowId, rowData) => {
        const member = rowData.username.props.text;
        const state = rowData.state;
        const role = 'Member';
        updateMember(member, role, state);
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Set active',
      onClick: (event, rowId, rowData) => {
        const member = rowData.username.props.text;
        const role = rowData.role;
        const state = 'Active';
        updateMember(member, role, state);
      },
    },
    {
      title: 'Set inactive',
      onClick: (event, rowId, rowData) => {
        const member = rowData.username.props.text;
        const role = rowData.role;
        const state = 'Pending';
        updateMember(member, role, state);
      },
    },
    {
      isSeparator: true,
    },
    {
      title: 'Remove from project',
      onClick: (event, rowId, rowData) => {
        const member = rowData.username.props.text;
        fetch(conf.api + '/project/' + project + '/member/' + member, {
          method: 'DELETE',
          credentials: 'include',
        })
          .then((response) => {
            reload();
          })
          .catch((error) => console.log(error));
      },
    },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    reload();
  };

  return (
    <Card id="project-members-card">
      <NewMember project={project} members={members} isOpen={isModalOpen} onClose={toggleModal} />
      <CardHeader
        actions={{
          actions: (
            <>
              <Button variant="control" aria-label="Add Member" onClick={() => setIsModalOpen(true)}>
                Add Member
              </Button>
            </>
          ),
          hasNoOffset: false,
          className: undefined,
        }}
        id="project-card-header-id"
      >
        <CardTitle>Members</CardTitle>
      </CardHeader>
      <CardBody>
        <ActionList kind="Project Members" columns={cols} rows={rows} actions={actions} />
      </CardBody>
    </Card>
  );
};

export { Project };
